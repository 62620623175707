(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("gl-matrix"), require("@cornerstonejs/core"), require("@kitware/vtk.js/Common/Core/Math"), require("@kitware/vtk.js/Filters/General/ImageMarchingSquares"), require("@kitware/vtk.js/Common/Core/DataArray"), require("@kitware/vtk.js/Common/DataModel/ImageData"), require("@kitware/vtk.js/Common/DataModel/PiecewiseFunction"), require("@kitware/vtk.js/Rendering/Core/ColorTransferFunction"), require("@kitware/vtk.js/Common/Core/CellArray"), require("@kitware/vtk.js/Common/Core/Points"), require("@kitware/vtk.js/Common/DataModel/PolyData"), require("@kitware/vtk.js/Rendering/Core/Mapper"), require("@kitware/vtk.js/Rendering/Core/Actor"), require("@kitware/vtk.js/Common/Core/MatrixBuilder"), require("@kitware/vtk.js/Interaction/Widgets/OrientationMarkerWidget"), require("@kitware/vtk.js/Rendering/Core/AnnotatedCubeActor"), require("@kitware/vtk.js/Rendering/Core/AxesActor"), require("@kitware/vtk.js/IO/XML/XMLPolyDataReader"));
	else if(typeof define === 'function' && define.amd)
		define(["gl-matrix", "@cornerstonejs/core", "@kitware/vtk.js/Common/Core/Math", "@kitware/vtk.js/Filters/General/ImageMarchingSquares", "@kitware/vtk.js/Common/Core/DataArray", "@kitware/vtk.js/Common/DataModel/ImageData", "@kitware/vtk.js/Common/DataModel/PiecewiseFunction", "@kitware/vtk.js/Rendering/Core/ColorTransferFunction", "@kitware/vtk.js/Common/Core/CellArray", "@kitware/vtk.js/Common/Core/Points", "@kitware/vtk.js/Common/DataModel/PolyData", "@kitware/vtk.js/Rendering/Core/Mapper", "@kitware/vtk.js/Rendering/Core/Actor", "@kitware/vtk.js/Common/Core/MatrixBuilder", "@kitware/vtk.js/Interaction/Widgets/OrientationMarkerWidget", "@kitware/vtk.js/Rendering/Core/AnnotatedCubeActor", "@kitware/vtk.js/Rendering/Core/AxesActor", "@kitware/vtk.js/IO/XML/XMLPolyDataReader"], factory);
	else if(typeof exports === 'object')
		exports["cornerstoneTools3D"] = factory(require("gl-matrix"), require("@cornerstonejs/core"), require("@kitware/vtk.js/Common/Core/Math"), require("@kitware/vtk.js/Filters/General/ImageMarchingSquares"), require("@kitware/vtk.js/Common/Core/DataArray"), require("@kitware/vtk.js/Common/DataModel/ImageData"), require("@kitware/vtk.js/Common/DataModel/PiecewiseFunction"), require("@kitware/vtk.js/Rendering/Core/ColorTransferFunction"), require("@kitware/vtk.js/Common/Core/CellArray"), require("@kitware/vtk.js/Common/Core/Points"), require("@kitware/vtk.js/Common/DataModel/PolyData"), require("@kitware/vtk.js/Rendering/Core/Mapper"), require("@kitware/vtk.js/Rendering/Core/Actor"), require("@kitware/vtk.js/Common/Core/MatrixBuilder"), require("@kitware/vtk.js/Interaction/Widgets/OrientationMarkerWidget"), require("@kitware/vtk.js/Rendering/Core/AnnotatedCubeActor"), require("@kitware/vtk.js/Rendering/Core/AxesActor"), require("@kitware/vtk.js/IO/XML/XMLPolyDataReader"));
	else
		root["cornerstoneTools3D"] = factory(root["window"], root["cornerstone3D"], root["@kitware/vtk.js/Common/Core/Math"], root["@kitware/vtk.js/Filters/General/ImageMarchingSquares"], root["@kitware/vtk.js/Common/Core/DataArray"], root["@kitware/vtk.js/Common/DataModel/ImageData"], root["@kitware/vtk.js/Common/DataModel/PiecewiseFunction"], root["@kitware/vtk.js/Rendering/Core/ColorTransferFunction"], root["@kitware/vtk.js/Common/Core/CellArray"], root["@kitware/vtk.js/Common/Core/Points"], root["@kitware/vtk.js/Common/DataModel/PolyData"], root["@kitware/vtk.js/Rendering/Core/Mapper"], root["@kitware/vtk.js/Rendering/Core/Actor"], root["@kitware/vtk.js/Common/Core/MatrixBuilder"], root["@kitware/vtk.js/Interaction/Widgets/OrientationMarkerWidget"], root["@kitware/vtk.js/Rendering/Core/AnnotatedCubeActor"], root["@kitware/vtk.js/Rendering/Core/AxesActor"], root["@kitware/vtk.js/IO/XML/XMLPolyDataReader"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__976__, __WEBPACK_EXTERNAL_MODULE__953__, __WEBPACK_EXTERNAL_MODULE__807__, __WEBPACK_EXTERNAL_MODULE__543__, __WEBPACK_EXTERNAL_MODULE__785__, __WEBPACK_EXTERNAL_MODULE__283__, __WEBPACK_EXTERNAL_MODULE__441__, __WEBPACK_EXTERNAL_MODULE__795__, __WEBPACK_EXTERNAL_MODULE__396__, __WEBPACK_EXTERNAL_MODULE__348__, __WEBPACK_EXTERNAL_MODULE__70__, __WEBPACK_EXTERNAL_MODULE__610__, __WEBPACK_EXTERNAL_MODULE__474__, __WEBPACK_EXTERNAL_MODULE__847__, __WEBPACK_EXTERNAL_MODULE__518__, __WEBPACK_EXTERNAL_MODULE__744__, __WEBPACK_EXTERNAL_MODULE__424__, __WEBPACK_EXTERNAL_MODULE__614__) => {
return 